<template>
  <div class="container">
    <div class="row faqs mx-auto mb-5">
      <div class="col-md-12">
        <h1 class="text-uppercase text-center mt-5 mb-5">
          Frequently Asked Questions
        </h1>
        <div class="accordion" id="faqAccordion">
          <div class="card" v-for="(faq, index) in faqs" :key="index">
            <div class="card-header" :id="'heading' + index">
              <h2 class="accordion-header">
                <button
                  :class="{
                    'arrow-open': activeIndex === index,
                    'arrow-closed': activeIndex !== index,
                  }"
                  class="accordion-button"
                  type="button"
                  @click="toggle(index)"
                  :aria-expanded="activeIndex === index ? 'true' : 'false'"
                  :aria-controls="'collapse' + index"
                >
                  {{ faq.question }}
                  <span></span>
                </button>
              </h2>
            </div>
            <div
              :id="'collapse' + index"
              class="accordion-collapse collapse"
              :class="{ show: activeIndex === index }"
              :aria-labelledby="'heading' + index"
              data-bs-parent="#faqAccordion"
            >
              <div class="card-body" v-html="faq.answer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQ',
  data() {
    return {
      faqs: [
        {
          question:
            'What do I do if I am having trouble participating in the program?',
          answer:
            'Please visit <a href="http://snipp.us/!csN7Y" target="_blank">http://snipp.us/!csN7Y</a>.',
        },
        {
          question: 'What are the participating cereal products?',
          answer:
            'You can view the full list of <a href="https://snipp.us/!ctjWQ" target="_blank">here</a>.',
        },
        {
          question: 'How long do I have to send in my receipt?',
          answer:
            'Participants with qualifying receipts will have up to 30 days after the promotional period to send in a receipt, no later than 3/2/25.',
        },
        {
          question:
            'How long after my submission will I receive my sports ball(s)?',
          answer:
            'Participants will receive their sports ball(s) within [8]  weeks of your order date.',
        },
        {
          question: 'What are the sports balls made of?',
          answer:
            '<b>Basketball</b>: synthetic rubber <br /> <b>Football</b>: PVC leather + synthetic rubber bladder<br /> <b>Soccer ball</b>: PVC + synthetic rubber bladder',
        },
        {
          question: 'What size are the sports balls?',
          answer:
            '<b>Basketball</b>: 27.5’’ circumference (69.85cm circumference) <br /> <b>Football</b>: 10.4’’ x 5.9’’ (26.5cm x 15cm) <br /> <b>Soccer ball</b>: 2.5’’ circumference (63.5cm circumference) <br />',
        },
        {
          question: 'Where were the sports balls made?',
          answer: 'China',
        },
        {
          question: 'Do I have to live in the United States to participate?',
          answer:
            'Yes. Must be a resident of the (50) United States or the District of Columbia and 16 years or older to participate.',
        },
        {
          question: ' Do I have to purchase a product to participate?',
          answer: 'Yes.',
        },
        {
          question:
            'Do I have to purchase all three products in one transaction?',
          answer: 'Yes.',
        },
        {
          question: 'Can I redeem more than 1 reward?',
          answer:
            'Yes. You can redeem up to 5 of each sports ball offered, maximum of 15 sports balls per person/email.',
        },
        {
          question:
            'Can I earn credit(s) from submitting my receipt(s) to the WK Kellogg’s text short-code or WK Kellogg’s email address?',
          answer: 'No.',
        },
        {
          question: 'Is there a mail-in option?',
          answer: 'No.',
        },
      ],
      activeIndex: null,
    };
  },
  methods: {
    toggle(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
  },
};
</script>

<style scoped>
#faqAccordion > .card {
  margin-bottom: 15px;
  border-radius: 0px;
  border: 1px solid #404040;
}

#faqAccordion > .card > .card-header {
  padding: 0px;
}

#faqAccordion .accordion-button {
  font-size: 1.1rem;
  color: #000;
  font-weight: 500;
  padding-right: 30px;
  box-shadow: none;
}

#faqAccordion .accordion-button:focus,
#faqAccordion .accordion-button.focus,
#faqAccordion .accordion-button:hover {
  text-decoration: none;
}

.accordion-button:not(.collapsed) {
  background-color: #e9ecef;
}
.accordion-button {
  transition: background-color 0.3s ease;
}
.accordion-button::after,
.accordion-button:not(.collapsed)::after {
  font-family: 'FontAwesome';
  background-image: none;
}
.accordion-button.arrow-open::after {
  content: '\f078';
}

.accordion-button.arrow-closed::after {
  content: '\f077';
}
</style>
