<template>
  <div>
    <div>
      <!-- Home page content -->
      <main role="main" class="container">
        <div class="text-center py-md-5 px-md-4 py-3">
          <h1 class="text-uppercase text-center mb-3 text-blue">
            Get a free sports ball
          </h1>
          <p class="page-subheader">
            When you purchase three (3) participating Kellogg’s Frosted
            Flakes<sup>®</sup>
            cereals.
          </p>
        </div>
        <h2 class="text-center mb-5">How To Participate</h2>

        <div class="row text-center mb-5 d-flex justify-content-center htsect">
          <div class="col-md-6 col-lg-4">
            <div class="">
              <img
                src="https://snippcheck.blob.core.windows.net/files/wkkc_kellogg_2007719/shop.png"
                alt="Shop"
                class="img-fluid mb-3"
              />
              <h4 class="text-blue">BUY</h4>
              <p>
                Buy any three (3) participating Kellogg’s Frosted
                Flakes<sup>®</sup>cereals between 8/12/24 and 1/31/25.
              </p>
            </div>
          </div>

          <div class="col-md-6 col-lg-4">
            <div class="">
              <img
                src="https://snippcheck.blob.core.windows.net/files/wkkc_kellogg_2007719/snap.png"
                alt="Snap"
                class="img-fluid mb-3"
              />
              <h4 class="text-blue">UPLOAD</h4>
              <p>
                Upload your receipt with qualifying purchases. Receipts must be
                submitted within 30 days, no later than 3/2/25.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="">
              <img
                src="https://snippcheck.blob.core.windows.net/files/wkkc_kellogg_2007719/submit.png"
                alt="Send"
                class="img-fluid mb-3"
              />
              <h4 class="text-blue">GET</h4>
              <p>
                Get one (1) free custom Kellogg’s Frosted Flakes<sup>®</sup>
                sports ball of your choosing by mail. Supply of each type of
                ball may vary. Limited to 5 of each of the 3 balls (for a total
                of 15). Sports ball must be claimed by 3/14/25. <br /><small
                  >(Sports balls will be shipped deflated, and consumers will
                  need to inflate. Air pump will not be included.)</small
                >
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div class="text-center readytosubmit mt-5 mb-5">
          <div class="row d-flex justify-content-between align-items-center">
            <div class="col-sm-12 text-sm-center">
              <h2 class="">Ready to Submit?</h2>
              <p class="mt-3">
                Click “GET STARTED” below to upload your receipt and receive
                your Kellogg’s<sup>®</sup> sports ball.
              </p>

              <div class="col-sm-12">
                <div class="mb-5">
                  <button
                    class="btn btn-link mt-3"
                    v-if="!showComponent"
                    @click="showComponent = true"
                  >
                    Get Started
                  </button>
                  <section class="wrapper" v-if="showComponent">
                    <component
                      v-if="showComponent"
                      :is="WidgetComponent"
                      campaign-id="f0300e06-7952-4058-af93-9c43d9c4938c"
                    ></component>
                  </section>
                </div>
              </div>
              <a href="https://snipp.us/!ctjWQ" class="anc-link" target="_blank"
                >Click here to view all participating cereal products. </a
              ><br />
            </div>
          </div>
        </div>
        <hr />
        <div class="pt-4 pb-4 mb-5">
          KELLOGG’S FROSTED FLAKES<sup>®</sup> MISSION TIGER SPORTS BALLS
          OFFER<br />
          Abbreviated Terms and Conditions. Internet access required. Begins
          8/12/24 and ends 1/31/25. Open to legal residents of US & DC, 16+.
          Subject to complete terms and conditions at
          <a
            href="https://www.frostedflakessportspromo.com/"
            class="anc-link"
            target="_blank"
            >www.FrostedFlakesSportsPromo.com</a
          >; complete list of participating products also found
          <a class="anc-link" href="https://snipp.us/!ctjWQ" target="_blank"
            >here</a
          >. Participating products must be purchased between 8/12/24 and
          1/31/25. Receipts must be uploaded at
          <a
            href="https://www.frostedflakessportspromo.com/"
            class="anc-link"
            target="_blank"
            >www.FrostedFlakesSportsPromo.com</a
          >. Message and data rates may apply. Receipts must be submitted by
          3/2/25 and sports ball must be claimed by 3/14/25. Void where
          prohibited. Sponsor: WK Kellogg Co, One Kellogg Square, North Tower,
          Battle Creek, MI 49017.
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import CampaignWidgets from './Homepagecomponents/CampaignWidgets.vue';

export default {
  components: { CampaignWidgets },
  props: {
    startDate: {
      type: Date,
      required: true,
    },
    endDate: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      showComponent: false,
      WidgetComponent: CampaignWidgets,
    };
  },
};
</script>

<style scoped>
@media screen and (max-width: 575px) {
}
</style>
